.app {
  text-align: center;
  padding: 40px;
}

.text {
  padding: 40px;
}

.button {
  padding: 20px;
  background: lightcoral;
  margin: 10px;
  cursor: pointer;
  text-decoration: none;
  color: black !important;
}
